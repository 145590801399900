import React, { useState } from "react";
import dashboardIcon from "../../assets/images/icons/dashboard.svg";
import manageAdminIcon from "../../assets/images/icons/manage-admin.svg";
import patientsIcon from "../../assets/images/icons/videos.svg";
import productionIcon from "../../assets/images/icons/production.svg";
import manageCustomerIcon from "../../assets/images/icons/manage-customers.svg";
import monetizationIcon from "../../assets/images/icons/monetization.svg";
import billingIcon from "../../assets/images/icons/billing.svg";
import notificationIcon from "../../assets/images/icons/notification.svg";
import analyticsIcon from "../../assets/images/icons/analytics.svg";
import settingsIcon from "../../assets/images/icons/settings.svg";
import doctorIcon from "../../assets/images/icons/player.svg";
import supportIcon from "../../assets/images/icons/support.svg";
import docIcon from "assets/images/icons/livetv.svg";
import adsIcon from "assets/images/icons/ads.svg";
import encodingIcon from "assets/images/icons/encoding.svg";
import productionsIcon from "assets/images/icons/productions.svg";
import homeIcon from "assets/images/icons/home.svg";
import { Link } from "react-router-dom";
import { Menu } from "antd";
import { useLocation } from "react-router-dom";
import logo from "../../assets/images/logo.svg";
import miniLogo from "../../assets/images/mini-logo.svg";

const { SubMenu } = Menu;
const rootSubmenuKeys = [
  "videoMenu",
  "liveTvmenu",
  "layout",
  "admin",
  "subscription",
  "library",
  "managelayout",
  "encoding",
];
export default function Sidebar(props) {
  const [openKeys, setOpenKeys] = React.useState([]);

  const onOpenChange = (keys) => {
    const latestOpenKey = keys.find((key) => openKeys.indexOf(key) === -1);
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
      console.log('keys', keys)
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const [rolesState, setRolesState] = useState(() => {
    const permission = JSON.parse(
      window.localStorage.getItem('audetails')
    )?.permissions;
    const roleModule = {};
    permission.forEach((value) => {
      if (value in roleModule) return;
      roleModule[value] = true;
    });
    console.log('roleModule',roleModule)
    return Object.keys(roleModule || {});
  });

  const items = [
    {
      key: "Home",
      label: "Home",
      icon: homeIcon,
      path: '/app/dashboard',
      role: 'DASHBOARD_PAYMENT_LIST'
    },
    {
      key: "manageCarer",
      label: "Manage Carer",
      icon: encodingIcon,
      subMenu: [
        {
          key: "carer",
          label: "View Carers",
          path: '/app/carer',
          role: 'CARER_LIST'
        },
        {
          key: "availability",
          label: "Availability",
          path: '/app/carers/availability',
          role: 'CARER_AVAILBLITY_LIST'
        },
        {
          key: "bookings",
          label: "Bookings",
          path: '/app/carers/bookings',
          role: 'CARER_BOOKINGS_LIST'
        },
        {
          key: "activity",
          label: "Activity",
          path: '/app/carers/activity',
          role: 'CARER_ACTIVITY_VIEW'
        },
      ]
    },
    {
      key: "managePatients",
      label: "Manage Patients",
      icon: patientsIcon,
      subMenu: [
        {
          key: "patients",
          label: "Patients",
          path: '/app/patients',
          role: 'PATIENT_LIST'
        },
      ]
    },
    {
      key: "manageDoc",
      label: "Manage Doctors",
      icon: doctorIcon,
      subMenu: [
        {
          key: "doctors",
          label: "Doctor",
          path: '/app/doctors',
          role: 'Doctor'
        },
        {
          key: "doctreatment",
          label: "Doctor Treatment",
          path: '/app/doctortasks',
          role: 'Task Master'
        },
        {
          key: "doccourse",
          label: "Doctor Courses",
          path: '/app/doctorcourses',
          role: 'Course Master'
        },
        {
          key: "doctordocument",
          label: "Doctor Document",
          path: '/app/doctordocument',
          role: 'Document Type'
        },
      ]
    },
    {
      key: "manageClinics",
      label: "Manage Clinic",
      icon: docIcon,
      subMenu: [
        {
          key: "clinics",
          label: "Clinics",
          path: '/app/clinic/space',
          role: 'Clinical Space'
        },
        {
          key: "rooms",
          label: "Rooms",
          path: '/app/room/space',
          role: 'Clinical Space'
        },
        {
          key: "clinictype",
          label: "Clinic Type",
          path: '/app/clinicalspace/type',
          role: 'Clinical Space'
        },
        {
          key: "clinicfeatures",
          label: "Clinic Features",
          path: '/app/clinicalspace/features',
          role: 'Clinical Space'
        },
        {
          key: "roomtype",
          label: "Room Type",
          path: '/app/clinicalspace/roomtype',
          role: 'Clinical Space'
        },
        {
          key: "roomfeatures",
          label: "Room Features",
          path: '/app/clinicalspace/room/features',
          role: 'Clinical Space'
        },
      ]
    },
    {
      key: "payment",
      label: "Payments",
      icon: monetizationIcon,
      subMenu: [
        {
          key: "patientaccount",
          label: "Patient",
          path: '/app/patient/account',
          role: 'PATIENT_REFUND_TRANSACTIONS_LIST'
        },
        {
          key: "careraccounts",
          label: "Carer",
          path: '/app/carers/account',
          role: 'CARER_TRANSACTIONS_VIEW'
        }
      ]
    },
    {
      key: "feedback",
      label: "Tickets",
      icon: supportIcon,
      path: '/app/support/feedback',
      role: 'TICKETS_LIST'
    },
    {
      key: "notification",
      label: "Notification",
      icon: notificationIcon,
      path: '/app/dashboard',
      role: 'Dashboard'
    },
    {
      key: "analytics",
      label: "Analytics",
      icon: analyticsIcon,
      path: '/app/dashboard',
      role: 'Dashboard'
    },
    {
      key: "Admin",
      label: "Manage Admins",
      icon: manageAdminIcon,
      subMenu: [
        {
          key: "Admin",
          label: "Admin Users",
          path: '/app/users',
          role: 'ADMIN_USERS_LIST'
        },
        {
          key: "roles",
          label: "Admin Roles",
          path: '/app/user-roles',
          role: 'ADMIN_ROLES_LIST'
        },
      ]
    },

  ];

  return (
    <aside className="side-menu">
      <div className="logo">
        <img src={props.collapse ? miniLogo : logo} />
      </div>
      <div className="side-nav">
        <Menu
          mode="inline"
          openKeys={openKeys}
          onOpenChange={onOpenChange}
          inlineCollapsed={!props.collapse}
        //selectedKeys={location.pathname}
        >
          {items.map((item) =>
            item?.subMenu?.filter((c) => rolesState.includes(c.role)).length > 0 ?
              (
                <SubMenu
                  key={item.key}
                  icon={<img src={item.icon} />}
                  title={item.label}
                >
                  {item?.subMenu.filter((c) => rolesState.includes(c.role)).map((c) =>
                    <Menu.Item key={c.key}>
                      <Link exact activeClassName="active" to={c.path}>
                        <span>{c.label}</span>
                      </Link>
                    </Menu.Item>
                  )}
                </SubMenu>
              )
              :
              // (console.log(item.role))
              (rolesState.includes(item.role))
              &&
              <Menu.Item key={item.key}>
                <Link exact to={item.path}>
                  <img src={item.icon} alt="" />
                  <span>{item.label}</span>
                </Link>
              </Menu.Item>
          )}

          {/* <Menu.Item key="/app/customerManagement">
              <NavLink exact activeClassName="active" to="/app/customerManagement">
              <img src={manageCustomerIcon} alt="" />
                <span>Customers</span>
              </NavLink>
            </Menu.Item>
          <Menu.Item key="/app/books">
            <NavLink exact to="/app/books">
              <img src={billingIcon} alt="" />
              <span>Books</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="/app/dailymanna">
            <NavLink exact to="/app/dailymanna">
              <img src={productionIcon} alt="" />
              <span>Daily Manna</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="/app/todayverse">
            <NavLink exact to="/app/todayverse">
              <img src={productionsIcon} alt="" />
              <span>Daily Verse </span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="/app/inspiration">
            <NavLink exact to="/app/inspiration">
              <img src={encodingIcon} alt="" />
              <span>Inspiration</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key="/app/events">
            <NavLink exact to="/app/events">
              <img src={dashboardIcon} alt="" />
              <span>Events</span>
            </NavLink>
          </Menu.Item>

          <Menu.Item key="/app/banners">
            <NavLink exact to="/app/banners">
              <img src={videoIcon} alt="" />
              <span>Banner Images</span>
            </NavLink>
          </Menu.Item>
          <SubMenu
            key="admin"
            icon={<img src={manageAdminIcon} />}
            title="Manage Users"
          >
            <Menu.Item key="/app/users">
              <NavLink exact activeClassName="active" to="/app/users">
                <span>Admin Users</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item key="/app/user-roles">
              <NavLink exact activeClassName="active" to="/app/user-roles">
                <span>Admin Roles</span>
              </NavLink>
            </Menu.Item> 
          </SubMenu> */}
          {/* <SubMenu
            key="contents"
            icon={<img src={manageCustomerIcon} />}
            title="Manage Contents"
          >
            <Menu.Item key="/app/imagecontents">
              <NavLink exact activeClassName="active" to="/app/imagecontents">
                <span>Image Contents</span>
              </NavLink>
            </Menu.Item>
            <Menu.Item key="/app/audiocontents">
              <NavLink exact activeClassName="active" to="/app/audiocontents">
                <span>Audio Contents</span>
              </NavLink>
            </Menu.Item>
          </SubMenu> */}
        </Menu>
      </div>
    </aside>
  );
}
