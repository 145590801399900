import React from 'react';
import { APP_NAME } from 'configs/AppConfig';

export default function Footer() {
  return (
    <footer className="footer">
      <span>© 2022 Connected Care</span>
      <div className='footer-links'>
        <a>About</a>
        <a>Support</a>
        <a>Contact Us</a>
      </div>
    </footer>
  );
}
