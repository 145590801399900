import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from "components/shared-components/Loading";

export const AppViews = ({ match }) => {
  console.log("match.url", match.url);
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <Route
          path={`${match.url}/dashboard`}
          component={lazy(() => import(`./dashboard`))}
        />
        <Route
          exact={true}
          path={`${match.url}/users/details/:id`}
          component={lazy(() => import(`./users/view/index`))}
        />
        <Route
          path={`${match.url}/users`}
          component={lazy(() => import(`./users`))}
        />
        <Route
          path={`${match.url}/user-roles`}
          component={lazy(() => import(`./user-roles`))}
        />
        <Route
          path={`${match.url}/carer`}
          component={lazy(() => import(`./carer`))}
        />
        
         <Route
          path={`${match.url}/carertasks`}
          component={lazy(() => import(`./carertasks`))}
        />

         <Route
          path={`${match.url}/course`}
          component={lazy(() => import(`./course`))}
        />
         <Route
          path={`${match.url}/setting/edit-profile`}
          component={lazy(() => import(`./pages/setting`))}
        />
         <Route
          path={`${match.url}/documenttype`}
          component={lazy(() => import(`./document`))}
        />
        <Route
          path={`${match.url}/patients`}
          component={lazy(() => import(`./patients`))}
        />
         <Route
          path={`${match.url}/doctors`}
          component={lazy(() => import(`./doctors`))}
        />
         <Route
          path={`${match.url}/doctortasks`}
          component={lazy(() => import(`./doctortreatment`))}
        />
        <Route
          path={`${match.url}/doctorcourses`}
          component={lazy(() => import(`./doctorcourses`))}
        />
         <Route
          path={`${match.url}/doctordocument`}
          component={lazy(() => import(`./doctordocument`))}
        />
          <Route
          path={`${match.url}/clinicalspace/type`}
          component={lazy(() => import(`./clinicaltype`))}
        />
          <Route
          path={`${match.url}/clinicalspace/room/features`}
          component={lazy(() => import(`./roomfeatures`))}
        />
         <Route
          path={`${match.url}/clinicalspace/features`}
          component={lazy(() => import(`./clinicfeature`))}
        />
          <Route
          path={`${match.url}/clinicalspace/roomtype`}
          component={lazy(() => import(`./roomtype`))}
        />
         <Route
          path={`${match.url}/clinic/space`}
          component={lazy(() => import(`./clinicals`))}
        />
         <Route
          path={`${match.url}/room/space`}
          component={lazy(() => import(`./rooms`))}
        />
         <Route
          path={`${match.url}/carers/availability`}
          component={lazy(() => import(`./availability/careravailability`))}
        />
         <Route
          path={`${match.url}/doctor/availability`}
          component={lazy(() => import(`./availability/doctoravailability`))}
        />
         <Route
          path={`${match.url}/clinics/availability`}
          component={lazy(() => import(`./availability/clinicavailablity`))}
        />
         <Route
          path={`${match.url}/carers/bookings`}
          component={lazy(() => import(`./bookings/carer`))}
        />
          <Route
          path={`${match.url}/doctor/bookings`}
          component={lazy(() => import(`./bookings/doctor`))}
        />
          <Route
          path={`${match.url}/clinics/bookings`}
          component={lazy(() => import(`./bookings/clinic`))}
        />
          <Route
          path={`${match.url}/transactions`}
          component={lazy(() => import(`./transactions`))}
        />
         <Route
          path={`${match.url}/refunds`}
          component={lazy(() => import(`./refunds`))}
        />
          <Route
          path={`${match.url}/careronboarding`}
          component={lazy(() => import(`./careronboarding`))}
        />
        <Route
          path={`${match.url}/paymentConfig`}
          component={lazy(() => import(`./config`))}
        />
         <Route
          path={`${match.url}/approve/page`}
          component={lazy(() => import(`./approvePage`))}
        />
         <Route
          path={`${match.url}/support/feedback`}
          component={lazy(() => import(`./support/feedback/index`))}
        />{' '}
        <Route
          path={`${match.url}/carers/activity`}
          component={lazy(() => import(`./activity/index`))}
        />
         <Route
          path={`${match.url}/stripeaccounts`}
          component={lazy(() => import(`./stripeaccounts`))}
        />
         <Route
          path={`${match.url}/statements`}
          component={lazy(() => import(`./statements`))}
        />
         <Route
          path={`${match.url}/patient/account`}
          component={lazy(() => import(`./patientAccount`))}
        />
         <Route
          path={`${match.url}/carers/account`}
          component={lazy(() => import(`./carerAccount`))}
        />

        <Redirect from={match.url} to={`${match.url}/dashboard`} />
      </Switch>
    </Suspense>
  );
};

export default AppViews;
