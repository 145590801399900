import { combineReducers } from 'redux';
import { DEFAULT_STATE, DEFAULT_STATE_FF_EF } from '../../utils/Constants';
import Auth from './Auth';
import Theme from './Theme';
// import { locales } from '../../lang';

const createReducer = (reducerName, defaultStateParam) => {
  const defaultState = defaultStateParam || DEFAULT_STATE;
  return (state = defaultState, action) => {
    switch (action.type) {
      case `${reducerName}_PENDING`:
      case `${reducerName}_FULFILLED`:
      case `${reducerName}_REJECTED`:
        return Object.assign({}, action.payload);
      default:
        return state;
    }
  };
};

const createStaticReducer = (reducerName, defaultStateParam) => {
  const defaultState = defaultStateParam || DEFAULT_STATE;
  return (state = defaultState, action) => {
    switch (action.type) {
      case `${reducerName}`:
        return Object.assign({}, action.payload);
      default:
        return state;
    }
  };
};

const reducers = combineReducers({
  theme: Theme,
  auth: Auth,
  login: createReducer('LOGIN_OTP', DEFAULT_STATE_FF_EF),
  validateOtpLogin: createReducer('VALIDATE_LOGIN_OTP', DEFAULT_STATE_FF_EF),
  forgotPasswordMail: createReducer('FORGOT_PASSWORD', DEFAULT_STATE_FF_EF),
  changepassword: createReducer('CHANGE_PASSWORD', DEFAULT_STATE_FF_EF),
  logout: createReducer('LOGOUT', { logout: false }),
  profileInfo: createReducer('PROFILE_INFO', DEFAULT_STATE_FF_EF),
  changeUserImage: createReducer('CHANGE_USER_IMAGE', DEFAULT_STATE_FF_EF),
  changeUserPassword: createReducer(
    'CHANGE_USER_PASSWORD',
    DEFAULT_STATE_FF_EF
  ),

  dashboardProfileStat: createReducer('DASHBOARD_PROFILE_STAT', DEFAULT_STATE_FF_EF),
  dashboardVerifyStat: createReducer('DASHBOARD_VERIFY_STATS', DEFAULT_STATE_FF_EF),
  dashboardAvailabilityStat: createReducer('DASHBOARD_AVAILABILITY_STATS', DEFAULT_STATE_FF_EF),
 
  dashboardIntTicketStat: createReducer('DASHBOARD_INTERNAL_TICKET_STATS', DEFAULT_STATE_FF_EF),

  roles: createReducer('ROLES', DEFAULT_STATE_FF_EF),
  roleAdd: createReducer('ROLE_ADD', DEFAULT_STATE_FF_EF),
  roleById: createReducer('ROLE_BY_ID', DEFAULT_STATE_FF_EF),
  roleDelete: createReducer('ROLE_DELETE', DEFAULT_STATE_FF_EF),
  roleForDD: createReducer('GET_ROLES_DD', DEFAULT_STATE_FF_EF),

  users: createReducer('USERS', DEFAULT_STATE_FF_EF),
  userAdd: createReducer('USER_ADD', DEFAULT_STATE_FF_EF),
  userById: createReducer('USER_BY_ID', DEFAULT_STATE_FF_EF),
  userDelete: createReducer('USER_DELETE', DEFAULT_STATE_FF_EF),

  permissions: createReducer('PERMISSIONS', DEFAULT_STATE_FF_EF),
  userPermissions: createReducer('USER_PERMISSIONS', DEFAULT_STATE_FF_EF),
  rolesPermissions: createReducer('PERMISSIONS_ROLES', DEFAULT_STATE_FF_EF),
 

  carer: createReducer('CARER', DEFAULT_STATE_FF_EF),
  carerAdd: createReducer('CARER_ADD', DEFAULT_STATE_FF_EF),
  carerById: createReducer('CARER_BY_ID', DEFAULT_STATE_FF_EF),
  carerDelete: createReducer('CARER_DELETE', DEFAULT_STATE_FF_EF),
  carerMeta: createReducer('CARER_META', DEFAULT_STATE_FF_EF),
  carerRating: createReducer('CARER_RATING', DEFAULT_STATE_FF_EF),
  countryForDD: createReducer('GET_COUNTRY_DD', DEFAULT_STATE_FF_EF),
  languagesDD: createReducer('GET_LANGUAGES_DD', DEFAULT_STATE_FF_EF),

  updateCarerDetails: createReducer('CARER_ABOUT_DETAILS', DEFAULT_STATE_FF_EF),
  getCarerDetails: createReducer('GET_CARER_ABOUT_DETAILS', DEFAULT_STATE_FF_EF),

  updatePricingDetails: createReducer('CARER_PRICING_DETAILS', DEFAULT_STATE_FF_EF),
  getPricingDetails: createReducer('GET_CARER_PRICING_DETAILS', DEFAULT_STATE_FF_EF),

  updateComplainceDetails: createReducer('CARER_COMPLAINCE_DETAILS', DEFAULT_STATE_FF_EF),
  getComplainceDetails: createReducer('GET_CARER_COMPLAINCE_DETAILS', DEFAULT_STATE_FF_EF),
  //doctype
  documentTypeMeta: createReducer('DOCUMENT_TYPE_META', DEFAULT_STATE_FF_EF),

  //coursedetails
  coursesDetails: createReducer('COURSES_DETAILS', DEFAULT_STATE_FF_EF),
  coursesDetailsAdd: createReducer('COURSES_DETAILS_ADD', DEFAULT_STATE_FF_EF),
  coursesDetailsById: createReducer('COURSES_DETAILS_BY_ID', DEFAULT_STATE_FF_EF),
  coursesDetailsDelete: createReducer('COURSES_DETAILS_DELETE', DEFAULT_STATE_FF_EF),

  //docdetails
  documentsDetails: createReducer('DOCUMENT_DETAILS', DEFAULT_STATE_FF_EF),
  documentsDetailsAdd: createReducer('DOCUMENT_DETAILS_ADD', DEFAULT_STATE_FF_EF),
  documentsDetailsById: createReducer('DOCUMENT_DETAILS_BY_ID', DEFAULT_STATE_FF_EF),
  documentsDetailsDelete: createReducer('DOCUMENT_DETAILS_DELETE', DEFAULT_STATE_FF_EF),
  carerDocs: createReducer(`CARER_DOCS`, DEFAULT_STATE_FF_EF),

  //patients
  patients: createReducer('PATIENTS', DEFAULT_STATE_FF_EF),
  patientsAdd: createReducer('PATIENTS_ADD', DEFAULT_STATE_FF_EF),
  patientsById: createReducer('PATIENTS_BY_ID', DEFAULT_STATE_FF_EF),
  patientsDelete: createReducer('PATIENTS_DELETE', DEFAULT_STATE_FF_EF),

  //doctors
  doctors: createReducer('DOCTORS', DEFAULT_STATE_FF_EF),
  doctorsAdd: createReducer('DOCTORS_ADD', DEFAULT_STATE_FF_EF),
  doctorsById: createReducer('DOCTORS_BY_ID', DEFAULT_STATE_FF_EF),
  doctorsDelete: createReducer('DOCTORS_DELETE', DEFAULT_STATE_FF_EF),

  updateDoctorDetails: createReducer('DOCTOR_ABOUT_DETAILS', DEFAULT_STATE_FF_EF),
  getDoctorDetails: createReducer('GET_DOCTOR_ABOUT_DETAILS', DEFAULT_STATE_FF_EF),

  updateDocPricingDetails: createReducer('DOCTOR_PRICING_DETAILS', DEFAULT_STATE_FF_EF),
  getDocPricingDetails: createReducer('GET_DOCTOR_PRICING_DETAILS', DEFAULT_STATE_FF_EF),

  updateDocComplainceDetails: createReducer('DOCTOR_COMPLAINCE_DETAILS', DEFAULT_STATE_FF_EF),
  getDocComplainceDetails: createReducer('GET_DOCTOR_COMPLAINCE_DETAILS', DEFAULT_STATE_FF_EF),



  docDocumentMeta: createReducer('DOCTOR_DOCUMENT_META', DEFAULT_STATE_FF_EF),
  //coursedetails 
  doctorCourseDetail: createReducer('DOCTOR_COURSE_DETAILS', DEFAULT_STATE_FF_EF),
  doctorCourseDetailAdd: createReducer('DOCTOR_COURSE_DETAILS_ADD', DEFAULT_STATE_FF_EF),
  doctorCourseDetailById: createReducer('DOCTOR_COURSE_DETAILS_BY_ID', DEFAULT_STATE_FF_EF),
  doctorCourseDetailDelete: createReducer('DOCTOR_COURSE_DETAILS_DELETE', DEFAULT_STATE_FF_EF),

  //docdetails
  doctorDocuments: createReducer('DOCTOR_DOCUMENT', DEFAULT_STATE_FF_EF),
  doctorDocumentsAdd: createReducer('DOCTOR_DOCUMENT_ADD', DEFAULT_STATE_FF_EF),
  doctorDocumentsById: createReducer('DOCTOR_DOCUMENT_BY_ID', DEFAULT_STATE_FF_EF),
  doctorDocumentsDelete: createReducer('DOCTOR_DOCUMENT_DELETE', DEFAULT_STATE_FF_EF),

  //carertasks
  carerTasks: createReducer('CARER_TASKS', DEFAULT_STATE_FF_EF),
  carerTasksAdd: createReducer('CARER_TASKS_ADD', DEFAULT_STATE_FF_EF),
  carerTasksById: createReducer('CARER_TASKS_BY_ID', DEFAULT_STATE_FF_EF),
  carerTasksDelete: createReducer('CARER_TASKS_DELETE', DEFAULT_STATE_FF_EF),
  carerTasksMeta: createReducer('CARER_TASKS_META', DEFAULT_STATE_FF_EF),

  //carercourses
  carerCourses: createReducer('CARER_COURSES', DEFAULT_STATE_FF_EF),
  carerCoursesAdd: createReducer('CARER_COURSES_ADD', DEFAULT_STATE_FF_EF),
  carerCoursesById: createReducer('CARER_COURSES_BY_ID', DEFAULT_STATE_FF_EF),
  carerCoursesDelete: createReducer('CARER_COURSES_DELETE', DEFAULT_STATE_FF_EF),
  carerCoursesMeta: createReducer('CARER_COURSES_META', DEFAULT_STATE_FF_EF),

  //doctortreatments
  doctorTreatments: createReducer('DOCTOR_TREATMENT', DEFAULT_STATE_FF_EF),
  doctorTreatmentsAdd: createReducer('DOCTOR_TREATMENT_ADD', DEFAULT_STATE_FF_EF),
  doctorTreatmentsById: createReducer('DOCTOR_TREATMENT_BY_ID', DEFAULT_STATE_FF_EF),
  doctorTreatmentsDelete: createReducer('DOCTOR_TREATMENT_DELETE', DEFAULT_STATE_FF_EF),
  doctorTreatmentsMeta: createReducer('DOCTOR_TREATMENT_META', DEFAULT_STATE_FF_EF),

  //doctorcourses
  doctorCourses: createReducer('DOCTOR_COURSES', DEFAULT_STATE_FF_EF),
  doctorCoursesAdd: createReducer('DOCTOR_COURSES_ADD', DEFAULT_STATE_FF_EF),
  doctorCoursesById: createReducer('DOCTOR_COURSES_BY_ID', DEFAULT_STATE_FF_EF),
  doctorCoursesDelete: createReducer('DOCTOR_COURSES_DELETE', DEFAULT_STATE_FF_EF),
  doctorCoursesMeta: createReducer('DOCTOR_COURSES_META', DEFAULT_STATE_FF_EF),

  //clicnical type
  clinicalType: createReducer('CLINICAL_TYPE', DEFAULT_STATE_FF_EF),
  clinicalTypeAdd: createReducer('CLINICAL_TYPE_ADD', DEFAULT_STATE_FF_EF),
  clinicalTypeById: createReducer('CLINICAL_TYPE_BY_ID', DEFAULT_STATE_FF_EF),
  clinicalTypeDelete: createReducer('CLINICAL_TYPE_DELETE', DEFAULT_STATE_FF_EF),
  clinicalTypeMeta: createReducer('CLINICAL_TYPE_META', DEFAULT_STATE_FF_EF),

  //clicnical features
  clinicalFeatures: createReducer('CLINICAL_FEATURES', DEFAULT_STATE_FF_EF),
  clinicalFeaturesAdd: createReducer('CLINICAL_FEATURES_ADD', DEFAULT_STATE_FF_EF),
  clinicalFeaturesById: createReducer('CLINICAL_FEATURES_BY_ID', DEFAULT_STATE_FF_EF),
  clinicalFeaturesDelete: createReducer('CLINICAL_FEATURES_DELETE', DEFAULT_STATE_FF_EF),
  clinicalFeaturesMeta: createReducer('CLINICAL_FEATURES_META', DEFAULT_STATE_FF_EF),

  // room features
  roomFeatures: createReducer('ROOM_FEATURES', DEFAULT_STATE_FF_EF),
  roomFeaturesAdd: createReducer('ROOM_FEATURES_ADD', DEFAULT_STATE_FF_EF),
  roomFeaturesById: createReducer('ROOM_FEATURES_BY_ID', DEFAULT_STATE_FF_EF),
  roomFeaturesDelete: createReducer('ROOM_FEATURES_DELETE', DEFAULT_STATE_FF_EF),
  roomFeaturesMeta: createReducer('ROOM_FEATURES_META', DEFAULT_STATE_FF_EF),

  // room type
  roomType: createReducer('ROOM_TYPE', DEFAULT_STATE_FF_EF),
  roomTypeAdd: createReducer('ROOM_TYPE_ADD', DEFAULT_STATE_FF_EF),
  roomTypeById: createReducer('ROOM_TYPE_BY_ID', DEFAULT_STATE_FF_EF),
  roomTypeDelete: createReducer('ROOM_TYPE_DELETE', DEFAULT_STATE_FF_EF),
  roomTypeMeta: createReducer('ROOM_TYPE_META', DEFAULT_STATE_FF_EF),

  // clinical space
  clinicalSpace: createReducer('CLINICAL_SPACE', DEFAULT_STATE_FF_EF),
  clinicalSpaceAdd: createReducer('CLINICAL_SPACE_ADD', DEFAULT_STATE_FF_EF),
  clinicalSpaceById: createReducer('CLINICAL_SPACE_BY_ID', DEFAULT_STATE_FF_EF),
  clinicalSpaceDelete: createReducer('CLINICAL_SPACE_DELETE', DEFAULT_STATE_FF_EF),
  clinicalSpaceMeta: createReducer('CLINICAL_SPACE_META', DEFAULT_STATE_FF_EF),

  // room space
  roomSpace: createReducer('ROOM_SPACE', DEFAULT_STATE_FF_EF),
  roomSpaceAdd: createReducer('ROOM_SPACE_ADD', DEFAULT_STATE_FF_EF),
  roomSpaceById: createReducer('ROOM_SPACE_BY_ID', DEFAULT_STATE_FF_EF),
  roomSpaceDelete: createReducer('ROOM_SPACE_DELETE', DEFAULT_STATE_FF_EF),
  roomSpaceMeta: createReducer('ROOM_SPACE_META', DEFAULT_STATE_FF_EF),

  //careravailability
  carerAvailability: createReducer('CARER_AVAILABILITY', DEFAULT_STATE_FF_EF),
  carerAvailabilityAdd: createReducer('CARER_AVAILABILITY_ADD', DEFAULT_STATE_FF_EF),

  //doctoravailability
  doctorAvailability: createReducer('DOCTOR_AVAILABILITY', DEFAULT_STATE_FF_EF),
  doctorAvailabilityAdd: createReducer('DOCTOR_AVAILABILITY_ADD', DEFAULT_STATE_FF_EF),

  //clinic avaiability
  clinicAvailability: createReducer('CLINIC_AVAILABILITY', DEFAULT_STATE_FF_EF),
  clinicAvailabilityAdd: createReducer('CLINIC_AVAILABILITY_ADD', DEFAULT_STATE_FF_EF),

  //get -bookings
  carerBooking: createReducer('CARER_BOOKING', DEFAULT_STATE_FF_EF),
  doctorBooking: createReducer('DOCTOR_BOOKING', DEFAULT_STATE_FF_EF),
  clinicBooking: createReducer('CLINIC_BOOKING', DEFAULT_STATE_FF_EF),

  //save-bookings
  carerBookingAdd: createReducer('CARER_BOOKING_ADD', DEFAULT_STATE_FF_EF),
  doctorBookingAdd: createReducer('DOCTOR_BOOKING_ADD', DEFAULT_STATE_FF_EF),
  clinicBookingAdd: createReducer('CLINIC_BOOKING_ADD', DEFAULT_STATE_FF_EF),

  //getbyid booking
  carerBookingById: createReducer('CARER_BOOKING_BY_ID', DEFAULT_STATE_FF_EF),
  doctorBookingById: createReducer('DOCTOR_BOOKING_BY_ID', DEFAULT_STATE_FF_EF),
  clinicBookingById: createReducer('CLINIC_BOOKING_BY_ID', DEFAULT_STATE_FF_EF),

  //cancelbooking
  carerBookingCancel: createReducer('CARER_BOOKING_CANCEL', DEFAULT_STATE_FF_EF),
  doctorBookingCancel: createReducer('DOCTOR_BOOKING_CANCEL', DEFAULT_STATE_FF_EF),
  clinicBookingCancel: createReducer('CLINIC_BOOKING_CANCEL', DEFAULT_STATE_FF_EF),

  //carermodify
  carerModify: createReducer('CARER_MODIFY', DEFAULT_STATE_FF_EF),
  carerModifyId: createReducer('CARER_MODIFY_BY_ID', DEFAULT_STATE_FF_EF),
  carerApproveById: createReducer('CARER_APPROVE_BY_ID', DEFAULT_STATE_FF_EF),

  //Feed Back
  feedbackList: createReducer('FEEDBACK', DEFAULT_STATE_FF_EF),
  feedbackListID: createReducer('FEEDBACK_LIST_BY_ID', DEFAULT_STATE_FF_EF),
  feedbackComment: createReducer('FEEDBACK_COMMENT_BY_ID', DEFAULT_STATE_FF_EF),
  commentAdd: createReducer('COMMENT_ADD', DEFAULT_STATE_FF_EF),
  feedbackAdd: createReducer('FEEDBACK_ADD', DEFAULT_STATE_FF_EF),
  feedbackIntComment: createReducer('FEEDBACK_INT_COMMENT_BY_ID', DEFAULT_STATE_FF_EF),
  commentInternalAdd: createReducer('COMMENT_INT_ADD', DEFAULT_STATE_FF_EF),

  //carerverify
  carerVerify: createReducer('CARER_VERIFY', DEFAULT_STATE_FF_EF),
  carerVerifyLogs: createReducer('GET_CARER_VERIFY_LOGS', DEFAULT_STATE_FF_EF),

  carerUnPaidBookings: createReducer('CARER_UNPAID_BOOKINGS', DEFAULT_STATE_FF_EF),
  addCarerPayments: createReducer('ADD_CARER_PAYMENTS', DEFAULT_STATE_FF_EF),
  carerPayments: createReducer('CARER_PAYMENTS', DEFAULT_STATE_FF_EF),
  carerPaymentsById: createReducer('CARER_PAYMENTS_BY_ID', DEFAULT_STATE_FF_EF),
  carerPendingPayments: createReducer('CARER_PENDING_PAYMENTS', DEFAULT_STATE_FF_EF),
  carerPendingPaymentsById: createReducer('CARER_PENDING_PAYMENTS_BY_ID', DEFAULT_STATE_FF_EF),

  patientPayment: createReducer('PATIENT_PAYMENT', DEFAULT_STATE_FF_EF),
  patientPaymentById: createReducer('PATIENT_PAYMENT_BY_ID', DEFAULT_STATE_FF_EF),

  patientBookings: createReducer('PATIENT_BOOKINGS', DEFAULT_STATE_FF_EF),
  patientBookingsById: createReducer('PATIENT_BOOKINGS_BY_ID', DEFAULT_STATE_FF_EF),

  patientRefunds: createReducer('PATIENT_REFUNDS', DEFAULT_STATE_FF_EF),
  updatePatientRefunds: createReducer('UPDATE_PATIENT_REFUNDS', DEFAULT_STATE_FF_EF),
  patientRefundById: createReducer('PATIENT_REFUNDS_BY_ID', DEFAULT_STATE_FF_EF),

  stripeAccount: createReducer('STRIPE_ACCOUNT', DEFAULT_STATE_FF_EF),
  stripeAccountLinks: createReducer('STRIPE_ACCOUNT_LINKS', DEFAULT_STATE_FF_EF),
 
  paymentDashboardCount: createReducer('GET_PAYMENT_DASHBOARD', DEFAULT_STATE_FF_EF),

  
});


export default reducers;
